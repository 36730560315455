import React, { Component } from 'react';
import axios from 'axios';
import Isvg from 'react-inlinesvg';
import img from '../../assets/image.svg';
import { API_ENDPOINT } from '../../constants';
import {
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

/**
 * Component for importing images with hotspots
 * @author   Dario Saric
 */
class HotspotPicker extends Component {
  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.removeHotspot = this.removeHotspot.bind(this);
    this.toggleDotMode = this.toggleDotMode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.clearAllHotspots = this.clearAllHotspots.bind(this);
    this.fetchFilterResults = this.fetchFilterResults.bind(this);

    this.state = {
      image: '',
      hotspots: [],
      modalOpen: false,
      dotMode: false,
      _loading: null,
      error: null,
      imageSet: false,
      title: '',
      newHotspotX: null,
      newHotspotY: null,
      filterResults: [],
      selectedResultId: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (prevProps.data !== data && data?.hotSpotImage) {
      this.setState({
        image: data.hotSpotImage.image,
        hotspots: data.hotSpotImage.hotspots || [],
        imageSet: true,
      });
    }
  }

  selectFile(e) {
    this.setState({
      error: null,
      dotMode: false,
    });

    let input = e.target;
    if (input.files && input.files[0]) {
      let formData = new FormData();
      formData.append('file', input.files[0]);

      this.setState({ _loading: '0%' });

      axios
        .post(
          API_ENDPOINT.replace('testapi', 'api') + this.props.endpoint,
          formData,
          {
            headers: {
              Accept: 'application/json',
              Authorization:
                typeof localStorage !== 'undefined'
                  ? `Bearer ${localStorage.getItem('authToken')}`
                  : null,
            },
            onUploadProgress: (progressEvent) => {
              this.setState({
                _loading:
                  Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  ) + '%',
              });
            },
          }
        )
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ error: response.data, _loading: null });
          } else {
            this.setState({
              _loading: null,
              imageSet: true,
              image: response.data,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status !== 200) {
              this.setState({ error: error.response.data, _loading: null });
            }
          }
        });
    }
  }

  handleImageClick(e) {
    if (!this.state.dotMode || !this.state.imageSet) return;

    const { offsetX, offsetY } = e.nativeEvent;
    const imageRect = e.currentTarget.getBoundingClientRect();
    const imageWidth = imageRect.width;
    const imageHeight = imageRect.height;

    const x = (offsetX / imageWidth) * 100;
    const y = (offsetY / imageHeight) * 100;

    this.setState({
      modalOpen: true,
      title: '',
      newHotspotX: x,
      newHotspotY: y,
    });
  }

  removeHotspot(index) {
    this.setState((prevState) => ({
      hotspots: prevState.hotspots.filter((_, i) => i !== index),
      modalOpen: false,
    }));
  }

  clearAllHotspots() {
    this.setState({
      hotspots: [],
      modalOpen: false,
    }, () => {
      this.props.onChange({
        image: this.state.image,
        hotspots: this.state.hotspots,
      });
    });
  }

  toggleDotMode() {
    this.setState((prevState) => ({
      dotMode: !prevState.dotMode,
    }));
  }

  handleInputChange(value) {
    this.setState({ title: value }, this.fetchFilterResults);
  }

  fetchFilterResults() {
    const { title } = this.state;

    axios
      .post(
        `${API_ENDPOINT}/data/products`,
        {
          entries: 10,
          page: 0,
          filter: title,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ filterResults: response.data?.items });
        }
      })
      .catch((error) => {
        console.error('Error fetching filter results:', error);
      });
  }

  handleModalSubmit() {
    const { title, newHotspotX, newHotspotY, selectedResultId } = this.state;
    
    // Check if title is empty
    if (title.trim() === '') {
      this.setState({ modalOpen: false });
      return;
    }
  
    // Update the hotspots state
    this.setState(
      (prevState) => ({
        hotspots: [
          ...prevState.hotspots,
          {
            x: newHotspotX,
            y: newHotspotY,
            productId: selectedResultId,
            title: title,
          },
        ],
        modalOpen: false,
        title: '',
        filterResults: [],
      }),
      () => {
        // Call onChange after state update
        this.props.onChange({
          image: this.state.image,
          hotspots: this.state.hotspots,
        });
      }
    );
  }

  handleModalClose() {
    this.setState({ modalOpen: false });
  }

  handleFilterResultClick(resultId, resultName) {
    this.setState({
      title: resultName,
      selectedResultId: resultId,
    });
  }

  render() {
    const { className = '', name } = this.props;
    const {
      hotspots,
      modalOpen,
      _loading,
      dotMode,
      title,
      imageSet,
      filterResults,
      image,
    } = this.state;

    return (
      <div className='hotSpotStyles'>
        <Label className='hotSpotLabel'>{this.props.label}</Label>
        <div
          className={`hotSpotWrapper ${className}`}
        >
          {!dotMode && (
            <Input name={name} type='file' onChange={this.selectFile} />
          )}
          <div className={`${ !dotMode ? 'pointer-event-none' : ''}`}>
            <div
              onClick={this.handleImageClick}
            >
              {image ? (
                <img
                  src={API_ENDPOINT.replace('testapi', 'api') + image}
                  alt='Uploaded'
                />
              ) : null}

              {_loading ? (
                <div className='progress-wrap'>
                  <div className='progress'>
                    <div style={{ width: _loading }}></div>
                  </div>
                  <span>{_loading}</span>
                </div>
              ) : null}

              {hotspots.map((hotspot, index) => (
                <div
                  key={index}
                  className='hotSpotDot'
                  style={{
                    position: 'absolute',
                    left: `${hotspot.x}%`,
                    top: `${hotspot.y}%`,
                    cursor: 'pointer',
                    zIndex: 10,
                  }}
                >
                  <div>
                    <div>{hotspot?.title} </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='bottom-picker'>
              <Isvg src={img} />
              <p>
                <span>{'Upload a file'}</span> {'or drag and drop'}
              </p>
            </div>
          </div>
        </div>
        {imageSet && (
          <div className='dot-mode-btn-wrapper'>
            <Button type='button' onClick={this.toggleDotMode}>
              {dotMode ? 'Exit Dot Mode' : 'Enter Dot Mode'}
            </Button>
            {dotMode && (
              <Button type='button' onClick={this.clearAllHotspots}>
                Clear All Hotspots
              </Button>
            )}
          </div>
        )}
        <Modal isOpen={modalOpen} toggle={this.handleModalClose} fade={false}>
          <ModalHeader toggle={this.handleModalClose}>Add Hotspot</ModalHeader>
          <ModalBody>
            <div className='filter-results'>
              <FormGroup>
                <Label>
                  <Input
                    type='text'
                    placeholder='Search...'
                    style={{ marginRight: 0 }}
                    value={title}
                    onChange={(e) => this.handleInputChange(e.target.value)}
                  />
                </Label>
              </FormGroup>
              <ListGroup>
                {filterResults.map((result) => (
                  <ListGroupItem
                    key={result.id}
                    className='d-flex flex-row align-items-center'
                    onClick={() =>
                      this.handleFilterResultClick(result._id, result.name.en)
                    }
                  >
                    <img
                      className='mr-3'
                      style={{ height: '40px', width: '40px' }}
                      src={
                        `${API_ENDPOINT}${result?.thumbnailExposed?.file}` ?? ''
                      }
                      alt={result.name.en}
                    />
                    {result.name.en}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.handleModalSubmit}>
              Add
            </Button>
            <Button color='secondary' onClick={this.handleModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default HotspotPicker;
